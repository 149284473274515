<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar class="">
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col class="d-flex" size="9">
              <ion-label class="fs-4 fw-500">{{ $t('orderB2b.paymentStatus') }}</ion-label>
            </ion-col>
            <ion-col>
              <ion-buttons class="ion-float-right" @click="onCloseButton()">
                <ion-icon size="small" :icon="close"></ion-icon>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="!loading">
      <!-- PAYMENT PENDING -->
      <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING" class="align-items-center">
        <ion-img :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]" class="payment-status-icon" />
        <ion-text color="warning" class="mt-6 ion-text-center">
          <h2>{{ $t('orderB2b.paymentPending') }}</h2>
        </ion-text>
        <ion-row class="mt-5 ion-padding-horizontal">
          <ion-text>
            {{ $t('orderB2b.paymentPendingMessage') }}
          </ion-text>
        </ion-row>
        <ion-row class="mt-3 ion-padding-horizontal">
          <ion-text>
            <strong>{{ $t('orderB2b.paymentPendingHeadline') }}</strong>
          </ion-text>
        </ion-row>
        <ion-row class="ion-padding-horizontal ion-text-uppercase mt-1">
          <ion-text>
            <strong>{{ dayjs.unix(paymentInfo.expires_at).format('hh:mm A, DD MMM YYYY') }}</strong>
          </ion-text>
        </ion-row>
        <ion-row class="ion-padding-horizontal ion-text-uppercase mt-3 align-items-center">
          <ion-img class="expiring-icon ion-float-left ml-3" src="/assets/icon/payment/timer.svg" />
          <ion-text color="danger" class="ion-padding-vertical timer">
            <strong v-if="remainingTime">{{ $t('orderB2b.paymentExpireIn') }} {{ remainingTime }}</strong>
            <strong v-if="!remainingTime">{{ $t('orderB2b.paymentTransactionExpired') }}</strong>
          </ion-text>
        </ion-row>
      </div>
      <!-- PAYMENT SUCCEED -->
      <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
        <ion-img :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]" class="payment-status-icon" />
        <ion-text color="primary" class="mt-6">
          <h2>{{ $t('orderB2b.paymentSuccess') }}</h2>
        </ion-text>
      </div>
      <!-- PAYMENT FAILED -->
      <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED" class="px-3">
        <ion-img :src="PAYMENT_STATUS_ICONS[paymentInfo.status.toUpperCase()]" class="payment-status-icon" />
        <ion-text color="danger" class="mt-6">
          <h2>{{ $t('orderB2b.paymentFail') }}</h2>
        </ion-text>
        <ion-text class="mt-5">
          {{ $t('orderB2b.paymentFailMessage') }}
        </ion-text>
      </div>
      <ion-card>
        <ion-card-content>
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col>{{ $t('orderB2b.orderNumber') }}</ion-col>
                <ion-col class="ion-text-right">#{{ orderId }}</ion-col>
              </ion-row>
              <ion-row>
                <ion-col>{{ $t('orderB2b.paymentMethod') }}</ion-col>
                <ion-col class="ion-text-right">{{ paymentInfo.paymentType.name }}</ion-col>
              </ion-row>
              <ion-row v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
                <ion-col>{{ $t('orderB2b.paymentTime') }}</ion-col>
                <ion-col class="ion-text-right">{{
                  dayjs(paymentInfo.updated_at).format('DD MMM YYYY HH:mm')
                }}</ion-col>
              </ion-row>
              <ion-row v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING">
                <ion-col>{{ $t('orderB2b.paymentTime') }}</ion-col>
                <ion-col class="ion-text-right">-</ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item lines="none">
            <ion-grid>
              <ion-row>
                <ion-col>{{ $t('orderB2b.totalAmount') }}</ion-col>
                <ion-col class="ion-text-right">
                  <span
                    class="text-primary"
                    v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED"
                    >{{ $t('paid') }}</span
                  >
                  <span
                    class="text-warning-400"
                    v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING"
                    >{{ $t('orderB2b.pendingPayment') }}</span
                  >
                  <span class="text-danger" v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED">{{
                    $t('paid_payment')
                  }}</span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col
                  class="ion-text-right"
                  v-if="paymentInfo.status !== PAYMENT_TRANSACTION_STATUS.FAILED"
                >
                  <strong>{{ user.country.currency_symbol }}{{ paymentInfo.amount.toFixed(2) }}</strong>
                </ion-col>
                <ion-col
                  class="ion-text-right"
                  v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED"
                >
                  <strong>{{ user.country.currency_symbol }}0.00</strong>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED">
            <ion-button expand="block" @click="goTo(`/b2b/order/purchase/order-detail/${orderId}`)">{{
              $t('orderB2b.viewOrderDetails')
            }}</ion-button>
            <ion-button fill="outline" expand="block" @click="goTo('/b2b/main/home')">{{
              $t('orderB2b.backToHome')
            }}</ion-button>
          </div>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.PENDING">
            <ion-button expand="block" @click="processCheckoutWithPaynowLink">{{
              $t('orderB2b.viewQRCode')
            }}</ion-button>
            <ion-button fill="outline" expand="block" @click="setOpenCancelConfirmation(true)">{{
              $t('orderB2b.cancelPaymentTransaction')
            }}</ion-button>
          </div>
          <div v-if="paymentInfo.status === PAYMENT_TRANSACTION_STATUS.FAILED">
            <ion-button
              fill="outline"
              expand="block"
              @click="goTo(`/b2b/order/purchase/order-detail/${orderId}`)"
              >{{ $t('orderB2b.viewOrderDetails') }}</ion-button
            >
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-content class="skeleton" v-if="loading">
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-content>
    <!-- modal online payment -->
    <ion-modal
      :is-open="isOpenCcancelConfirmation"
      @didDismiss="setOpenCancelConfirmation(false)"
      class="modal-confirm"
      :backdropDismiss="true"
    >
      <modal-confirm-cancel-payment
        @on-no="setOpenCancelConfirmation(false)"
        @on-yes="cancelPaymentTransaction"
      ></modal-confirm-cancel-payment>
    </ion-modal>
    <ion-loading
      :is-open="isOpenRef"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { PAYMENT_STATUS_ICONS } from '@/modules/b2b/constants';
import { cancelPrepayment, paymentTransactionDetailsForOrder } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
import { PAYMENT_TRANSACTION_STATUS } from '@/services/shared/helper/constants';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { isPlatform } from '@ionic/vue';
import dayjs from 'dayjs';
import { checkmarkCircleOutline, close } from 'ionicons/icons';
import { defineComponent, inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import ModalConfirmCancelPayment from './ModalConfirmCancelPayment.vue';
const { mapActions } = createNamespacedHelpers('b2b/purchase');

export default defineComponent({
  name: 'PaymentTransactionDetails',
  components: {
    ModalConfirmCancelPayment
  },
  props: {},
  inject: ['$storage'],
  setup() {
    const ionRouter = inject('navManager');
    const loading = ref(true);
    const setLoading = (value) => (loading.value = value);

    const isOpenRef = ref(false);
    const setOpenLoading = (value) => (isOpenRef.value = value);

    const paymentInfo = ref({});
    const setPaymentInfo = (data) => (paymentInfo.value = data);
    const remainingTime = ref('00 HRS 00 MINS 00 SECS');

    const isOpenCcancelConfirmation = ref(false);
    const setOpenCancelConfirmation = (data) => (isOpenCcancelConfirmation.value = data);

    const route = useRoute();
    return {
      close,
      checkmarkCircleOutline,
      ionRouter,
      loading,
      setLoading,
      paymentInfo,
      route,
      dayjs,
      PAYMENT_TRANSACTION_STATUS,
      PAYMENT_STATUS_ICONS,
      remainingTime,
      setPaymentInfo,
      isOpenCcancelConfirmation,
      setOpenCancelConfirmation,
      InAppBrowser,
      isOpenRef,
      setOpenLoading
    };
  },
  async ionViewDidEnter() {
    this.orderId = this.route.params.orderId;
    if (!this.orderId && isNaN(this.orderId)) {
      this.goTo('/b2b/main/home');
    }
    this.user = await this.$storage.getUser();
    await this.getPaymentTransactionDetails();
  },
  data() {
    return {
      timer: null,
      qrCodeTimer: null,
      orderId: null,
      user: null
    };
  },
  beforeUnmount() {
    if (this.timer) clearInterval(this.timer);
    if (this.qrCodeTimer) clearInterval(this.qrCodeTimer);
  },
  methods: {
    ...mapActions([ACTIONS.GET_PURCHASE]),
    async processCheckoutWithPaynowLink() {
      if (isPlatform('ios')) {
        // handle for mobile
        await this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentInfo.payment_link
        });

        // long pooling to get payment transaction status changes
        this.qrCodeTimer = setInterval(async () => {
          const { data } = await apolloClient.mutate({
            mutation: paymentTransactionDetailsForOrder,
            variables: {
              orderId: parseInt(this.orderId)
            }
          });

          // check return value to close browser
          const paymentTransaction = data.paymentTransactionDetailsForOrder;
          if (
            paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.FAILED ||
            paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED
          ) {
            this.$browser.close();
            // remove interval
            this.qrCodeTimer ? clearInterval(this.qrCodeTimer) : null;
            // remove listener
            this.$browser.removeAllListeners();
            await this.getPaymentTransactionDetails();
          }
        }, 1000);

        await this.$browser.addListener('browserFinished', async () => {
          // remove interval
          this.qrCodeTimer ? clearInterval(this.qrCodeTimer) : null;
          // remove listener
          this.$browser.removeAllListeners();
          await this.getPaymentTransactionDetails();
        });
      } else if (isPlatform('android')) {
        this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentInfo.payment_link
        });
      } else {
        // handle for web
        window.location.href = this.paymentInfo.payment_link;
      }
    },
    async getPaymentTransactionDetails() {
      // check checkout session key
      try {
        const { data } = await apolloClient.mutate({
          mutation: paymentTransactionDetailsForOrder,
          variables: {
            orderId: parseInt(this.orderId)
          }
        });

        if (data?.paymentTransactionDetailsForOrder) {
          this.setPaymentInfo(data.paymentTransactionDetailsForOrder);
          this.setLoading(false);

          // process countdown remaining time for payment
          if (data?.paymentTransactionDetailsForOrder.status === PAYMENT_TRANSACTION_STATUS.PENDING) {
            this.timer = setInterval(() => {
              const diffTime =
                dayjs.unix(this.paymentInfo.expires_at).diff(dayjs(), 'milliseconds') > 0
                  ? dayjs.duration(dayjs.unix(this.paymentInfo.expires_at).diff(dayjs()))
                  : undefined;
              if (diffTime) {
                this.remainingTime = `${diffTime.format('HH')} HRS ${diffTime.format(
                  'mm'
                )} MINS ${diffTime.format('ss')} SECS`;
              }
              if (diffTime.format('HH:mm:ss') === '00:00:00') {
                clearInterval(this.timer);
              }
            }, 1000);

            // start checking order status
            // long pooling to get payment transaction status changes
            this.qrCodeTimer = setInterval(async () => {
              const { data } = await apolloClient.mutate({
                mutation: paymentTransactionDetailsForOrder,
                variables: {
                  orderId: parseInt(this.orderId)
                }
              });

              // check return value to close browser
              const paymentTransaction = data.paymentTransactionDetailsForOrder;
              if (
                paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.FAILED ||
                paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.SUCCEEDED
              ) {
                // remove interval
                this.timer ? clearInterval(this.timer) : null;
                this.qrCodeTimer ? clearInterval(this.qrCodeTimer) : null;
                await this.getPaymentTransactionDetails();
              }
            }, 1000);
          }
        } else {
          this.goTo('/b2b/main/home');
        }
      } catch (e) {
        console.log(`Payment transaction error: ${e.message}`);
        this.goTo('/b2b/main/home');
      }
    },

    async cancelPaymentTransaction() {
      this.setOpenCancelConfirmation(false);
      this.setOpenLoading(true);

      // call cancel payment transaction API
      const { data } = await apolloClient.mutate({
        mutation: cancelPrepayment,
        variables: {
          stripeCheckoutSessionId: this.paymentInfo.checkout_session_id
        }
      });

      if (data.cancelPrepayment) {
        // delay for a while for stripe webhook is triggered
        setTimeout(() => {
          this.setOpenLoading(false);
          this.goTo(`/b2b/order/purchase/order-detail/${this.orderId}`);
        }, 1000);
      } else {
        // show error message
        this.setOpenLoading(false);
        console.log(`cancel prepayment error ${JSON.stringify(data)}`);
      }
    },

    async onCloseButton() {
      await this[ACTIONS.GET_PURCHASE]({
        params: {
          offset: 0,
          isIncludeTotalCount: true
        }
      });
      if (this.timer) clearInterval(this.timer);
      if (this.qrCodeTimer) clearInterval(this.qrCodeTimer);
      this.goTo(`/b2b/order/purchase`);
    },
    goTo(pathTarget) {
      this.$router.push(pathTarget);
    }
  }
});
</script>
<style lang="scss" scoped>
ion-content {
  --background: #f5f5f5;
  text-align: center;
}
.checkmark-done {
  width: 33px;
  height: 33px;
}
.payment-status-icon {
  height: 100px;
}
ion-text {
  width: 100%;
}
ion-content {
  --overflow: hidden;
}
.expiring-icon {
  width: 30px;
  margin: auto;
  margin-right: 10px;
}
.timer {
  width: auto;
  margin: auto;
  margin-left: 0px;
}
.modal-confirm {
  --width: calc(100% - 3rem);
  --height: 35%;
  &::part(content) {
  }
}
</style>
